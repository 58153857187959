import React, {useEffect, useState} from 'react';
import {doc, getDoc, setDoc} from 'firebase/firestore';
import './App.css';
import './kalender.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Luke from './luke';
import Snowfall from 'react-snowfall';
import {defaultLuker2022,} from './luker/luker2022';
import {Button, Form, InputGroup, Modal} from 'react-bootstrap';
import sigmund from './images/sigmund.png';
import santa from './images/santa.png';
import raindeer from './images/raindeer2.png';
import {alleLukerÅpnet, lukerÅpnetDefault} from './consts';
import {db} from './firebase';
import {finnLuker, godkjenteNavn, validerNavn} from "./utils";
import {LukeType} from "./typer";

// https://colorpalettes.net/color-palette-4284/

function App() {
    const isDev = window.location.hostname === 'localhost';
    const isChristmasMode = (isDev || new Date() >= new Date("12.01.2024"))
    console.log('isChristmasMode', isChristmasMode);
    const [show, setShow] = useState(true);
    const [kalenderNavn, setKalenderNavn] = useState('');
    const [kalenderÅr, setKalenderÅr] = useState(isChristmasMode ? 2024 : 2022);
    const [feilmelding, setFeilmelding] = useState('');
    const [luker, setLuker] = useState<LukeType[]>(defaultLuker2022);
    const [lukerÅpnet, setLukerÅpnet] = useState(lukerÅpnetDefault);

    const hentÅpneLuker = async (navn: string): Promise<any> => {
        if (kalenderÅr === new Date().getFullYear()) {
            const docRef = doc(db, 'julekalender', navn);
            const document = await getDoc(docRef);
            if (document.exists()) {
                setLukerÅpnet(document.data().lukerÅpnet);
            } else {
                setLukerÅpnet(lukerÅpnetDefault);
            }
        } else {
            setLukerÅpnet(alleLukerÅpnet);
        }
    };

    useEffect(() => {
        const storageNavn = localStorage.getItem('navn');
        if (storageNavn !== null && godkjenteNavn.includes(storageNavn)) {
            byttKalender(storageNavn, kalenderÅr);
            setKalenderNavn(storageNavn);
            setShow(false);
        }
    }, []);

    useEffect(() => {
        hentÅpneLuker(kalenderNavn);
        byttKalender(kalenderNavn, kalenderÅr);
    }, [kalenderNavn, kalenderÅr]);


    const byttKalender = (navn: string, år: number) => {
        setLuker(finnLuker(navn, år));
        hentÅpneLuker(navn);
    };

    const handleSendInn = () => {
        if (validerNavn(kalenderNavn) === true) {
            setShow(false);
            byttKalender(kalenderNavn, kalenderÅr);
        } else {
            setFeilmelding(String(validerNavn(kalenderNavn)));
        }
    };

    const handleLukeOpen = async (lukeNummer: number) => {
        if (kalenderÅr === new Date().getFullYear()) {
            const nyeLukerÅpnet = [...lukerÅpnet];
            nyeLukerÅpnet[lukeNummer - 1] = !nyeLukerÅpnet[lukeNummer - 1];
            setLukerÅpnet(nyeLukerÅpnet);
            try {
                const docRef = await setDoc(doc(db, 'julekalender', kalenderNavn), {
                    lukerÅpnet: nyeLukerÅpnet,
                });
            } catch (error) {
                console.log(error);
            }
        }
    };

    return (
        <div className='app'>
            <Snowfall
                style={{
                    zIndex: '-2',
                    position: 'fixed',
                    width: '100vw',
                    height: '100vh',
                }}
            />
            <div className='santa-container pc'>
                <img src={santa} alt='Santa' className='santa pc'/>
            </div>
            <div className='raindeer-container pc'>
                <img src={raindeer} alt='raindeer' className='raindeer pc'/>
            </div>
            <div className='header'>
                <h1>Sigmund's julekalender</h1>
                <img src={sigmund} alt='sigmund' className='pc'/>
                <div className='sigmund_row'>
                    <img src={sigmund} alt='sigmund' className='mobile'/>
                    <img src={sigmund} alt='sigmund' className='mobile'/>
                    <img src={sigmund} alt='sigmund' className='mobile'/>
                </div>
            </div>
            <div className='kalender'>
                {luker.map((luke: LukeType, index: number) => (
                    <Luke
                        lukeNummer={luke.id}
                        image={luke.image}
                        key={luke.id}
                        text={luke.text}
                        open={lukerÅpnet[index]}
                        calenderName={kalenderNavn}
                        handleLukeOpen={handleLukeOpen}
                    />
                ))}
            </div>
            <select onChange={(event) => setKalenderNavn(event.target.value)} value='andre'>
                <option value='andre' disabled hidden>
                    Andre kalendere
                </option>
                <option value='mamma'>Mamma</option>
                <option value='pappa'>Pappa</option>
                <option value='hallvard'>Hallvard</option>
                <option value='karine'>Karine</option>
                {localStorage.getItem('navn') === 'herborg' && (
                    <option value='herborg'>Herborg</option>
                )}
            </select>
            {isChristmasMode && (
                <select onChange={(event) => {
                    setKalenderÅr(Number(event.target.value))
                }} value='år'>
                    <option value='år' disabled hidden>
                        År
                    </option>
                    <option value={2022}>2022</option>
                    <option value={2024}>2024</option>
                </select>
            )}
            <Modal
                size='sm'
                aria-labelledby='contained-modal-title-vcenter'
                centered
                show={show}
            >
                <Modal.Header closeButton>
                    <Modal.Title id='contained-modal-title-vcenter'>
                        Hva heter du?
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InputGroup className='mb-3'>
                        <Form.Control
                            onChange={(e) => setKalenderNavn(e.target.value)}
                            placeholder='Navn'
                            aria-label='Navn'
                            aria-describedby='basic-addon1'
                        />
                    </InputGroup>
                    <p className='red'>{feilmelding}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleSendInn} variant='success'>
                        Send Inn
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default App;
