import {
    defaultLuker2022,
    hallvardLuker2022,
    herborgLuker2022,
    karineLuker2022,
    mammaLuker2022,
    pappaLuker2022,
} from './luker/luker2022';
import {
    defaultLuker2024,
    hallvardLuker2024,
    herborgLuker2024,
    karineLuker2024,
    mammaLuker2024,
    pappaLuker2024,
} from './luker/luker2024';
import {LukeType} from "./typer";

export const finnLuker = (kalenderNavn: string, kalenderÅr: number): LukeType[] => {
    if (kalenderNavn === 'mamma') {
        if (kalenderÅr === 2022) {
            return mammaLuker2022;
        }
        return mammaLuker2024;
    } else if (kalenderNavn === 'pappa') {
        if (kalenderÅr === 2022) {
            return pappaLuker2022;
        }
        return pappaLuker2024;
    } else if (kalenderNavn === 'hallvard') {
        if (kalenderÅr === 2022) {
            return hallvardLuker2022;
        }
        return hallvardLuker2024;
    } else if (kalenderNavn === 'karine') {
        if (kalenderÅr === 2022) {
            return karineLuker2022;
        }
        return karineLuker2024;
    } else if (kalenderNavn === 'herborg') {
        if (kalenderÅr === 2022) {
            return herborgLuker2022;
        }
        return herborgLuker2024;
    }
    return defaultLuker2024
}


export const godkjenteNavn = ['mamma', 'pappa', 'hallvard', 'karine', 'herborg'];

export const validerNavn = (navn: String) => {
    const mammaNavn = ['kristine', 'kristine berbom'];
    const pappaNavn = ['thomas', 'thomas berbom'];
    const navnLowerCase = navn.toLocaleLowerCase();
    if (godkjenteNavn.includes(navnLowerCase)) {
        localStorage.setItem('navn', navnLowerCase);
        return true
    }
    if (mammaNavn.includes(navnLowerCase)) {
        return ('Du er ikke Kristine, du er Mamma!');
    }
    if (pappaNavn.includes(navnLowerCase)) {
        return ('Du er ikke Thomas, du er Pappa!');
    }
    return 'Kjenner ikke navnet, prøv igjen';
};
